.products-page-gallery {
    margin-bottom: 1rem;
    .image-gallery-icon-fullscreen {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        background: transparent;
        border: none;
        img {
            cursor: pointer;
            width: 2rem;
        }
    }

    .image-gallery-content.fullscreen {
        background: #d0cfca !important;
    }
}
