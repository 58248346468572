.Toastify__progress-bar--default {
    background: var(--secondary-background-color) !important;
}

.product-details-content {
    margin-bottom: 1rem;
    h2 {
        font-size: 30px;
        font-weight: bold;
        color: var(--secondary-background-color);
    }
    .go-to-faq {
        cursor: pointer;
        text-decoration: underline;
        font-size: 15px;
        color: var(--secondary-background-color);
    }
    .auction-title {
        font-weight: 300;
        margin-right: 0.5rem;
        color: #929292;
    }
    .auction-content {
        font-weight: bold;
        color: var(--primary-background-color);
    }
    .description-lots {
        font-size: 13px;
        margin-top: 1rem;
    }
    .product-info {
        margin: 1rem 0rem;
        padding: 1rem 1rem;
        border-top: 1px solid rgb(190, 190, 190);
        border-bottom: 1px solid rgb(190, 190, 190);
        li {
            list-style-type: none;
            color: var(--primary-background-color);
            font-weight: bold;
            margin-bottom: 0.5rem;
            span {
                color: #929292;
                font-weight: normal;
                text-transform: uppercase;
            }
        }
    }
    .buy-checkbox-btn {
        .winning {
            background-color: var(--button-offer-winning) !important;
            border-color: var(--button-offer-winning) !important;
        }
        .not_offered {
            background-color: var(--button-offer-not_offered) !important;
            border-color: var(--button-offer-not_offered) !important;
            color: var(--text-color-with-secondary) !important;
        }
        .not_auctioned {
            background-color: var(--button-offer-not_auctioned) !important;
            border-color: var(--button-offer-not_auctioned) !important;
        }
        .auctioned {
            background-color: var(--button-offer-auctioned) !important;
            border-color: var(--button-offer-auctioned) !important;
        }
        button  {
            margin-bottom: 0.25rem;
        }
    }
}
