.carousel-control-prev,
.carousel-control-next {
    opacity: 1 !important;
}

@media screen and (max-width: 768px) {
    .carousel {
        margin-top: 0;
    }
}

.carousel {
    .carousel-inner{
        img{
            object-fit: cover;
        }
    }
}
