.facebook-button-class {
    padding: 0.2rem 2rem;
    background: #1876f2;
    border: white;
    color: white;
    border: 1px solid transparent;
    font-family: "TrebuchetMS", "Trebuchet MS";
    i {
        margin-right: 8px;
    }
}

.facebook-button-class:hover {
    background: transparent;
    border: 1px solid #1876f2;
    color: #1876f2;
}

.signup-content {
    .signup-form {
        background: #f0f0f0;
        padding: 2rem;
        border-radius: 0.75rem;
    }
    .btn-primary {
        background: var(--secondary-background-color);
        border-color: var(--secondary-background-color);
        color: var(--text-color-with-secondary);
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0.75rem 1rem;
        margin-top: 0.5rem;
    }
    .btn-primary:hover {
        background: var(--secondary-background-color);
        border-color: var(--secondary-background-color);
        color: var(--text-color-with-secondary);
    }
    .forgot-password {
        font-size: 14px;
        text-decoration: underline;
        color: var(--primary-background-color);
    }
    .form-group {
        input,
        select {
            border-radius: 0.45rem;
            border: none;
            background: #e1e1e1;
        }
    }
}
