.nav-tabs-custom {
    color: var(--primary-background-color) !important;
}

.signup-form {
    color: #495057;
    background: #f0f0f0;
    padding: 2rem;
    border-radius: 0.75rem;
    .buttons {
        button {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.1rem;
            text-transform: uppercase;
            border: none;
            border-radius: 10px;
            padding: 0.5rem 1rem;
            color: var(--text-color-with-secondary);
            background-color: var(--secondary-background-color);
        }
        a {
            text-decoration: underline;
            font-size: 16px;
            text-transform: uppercase;
            color: var(--primary-background-color);
            letter-spacing: 1px;
        }
    }
    input,
    select {
        border-radius: 0.45rem;
        border: none;
        background: #e1e1e1;
    }
}

.nav-tabs-custom.nav-item.nav-link.active {
    color: var(--text-color-with-secondary) !important;
    background: var(--secondary-background-color);
    border-color: transparent;
}

@media screen and (max-width: 990px) {
    .nav-link {
        padding: 0.5rem 0.5rem !important;
    }
}
